import axios from "axios"
import {
    BillingSettingKey, BillingSystem, OrderListItem, OrderListItemImpl, OrderListItemResponse,
    SetOrderStateRequest, Transaction, UserBillingState, UserListItem, UserProfile
} from "./models"
import {API_HOST, SELF_HOST} from "../config/config";

interface ListResponse<T> {
    items: T[],
    nextPageToken?: string
}

export interface BillingSettingResponse {
    key: BillingSettingKey;
    value: string;
}

export interface TariffsResponse extends ListResponse<Tariff> {
}

export interface Tariff {
    id: string;
}

export enum BillinOrderState {
    Completed = "COMPLETED",
    FraudScreeningSuspicious = "IN_PROGRESS/FRAUD_SCREENING/SUSPICIOUS",
    FraudScreeningSuspended = "IN_PROGRESS/FRAUD_SCREENING/SUSPENDED",
}

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

function getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

class ApiClient {
    private readonly token: string;
    private readonly selfHost: string;
    private readonly apiHost: string;
    private readonly config: any;

    constructor() {
        this.token = getCookie("access-token");
        this.selfHost = SELF_HOST;
        this.apiHost = API_HOST;
        this.config = {
            headers: {
                "Authorization": "Bearer " + this.token
            }
        };
    }

    async fetchActiveUsersCount(): Promise<number> {
        const result = await axios.get<{ count: number }>(
            `${this.selfHost}/users/active`, this.config);

        return result.data.count;
    }

    async fetchBillingSetting(key: BillingSettingKey): Promise<BillingSettingResponse> {
        const uri = `${this.apiHost}/billing/v2/settings/${key}`;
        const result = await axios.get<BillingSettingResponse>(uri, this.config);
        return result.data;
    }

    async fetchOrderList(nextPageToken: string,
                         states: BillinOrderState[],
                         maxResults: number = 50): Promise<ListResponse<OrderListItem>> {
        const filter = states.join(",");
        const pageTokenParam = (nextPageToken ? `&pageToken=${nextPageToken}` : "");
        const maxResultsParam = `&maxResults=${maxResults}`;
        const systemParam = `&system=GENERIC`;
        const uri = `${this.apiHost}/billing/v2/orders?state=${filter}${systemParam}${pageTokenParam}${maxResultsParam}`;

        const result = await axios.get<ListResponse<OrderListItemResponse>>(uri, this.config);

        const orders = result
            .data
            .items
            .map(OrderListItemImpl.fromOrderListItemResponse);

        return {
            items: orders,
            nextPageToken: result.data.nextPageToken
        };
    }

    async fetchTariffsBySystem(system: BillingSystem): Promise<TariffsResponse> {
        const result = await axios.get<TariffsResponse>(
            `${this.apiHost}/billing/v2/tariffs/${system}`, this.config);
        return result.data
    }

    async fetchTotalLastDays(): Promise<{ items: { amount: number, date: Date }[] }> {
        const result = await axios.get<{ items: any[] }>(
            `${this.selfHost}/users/total`, this.config);

        const items = result.data.items;
        return {
            items: items.map(it => {
                return {
                    amount: it.amount,
                    date: new Date(it.date)
                }
            })
        };
    }

    async fetchUsersList(orderBy: string,
                         orderDirection: string,
                         search: string | null,
                         pageToken: string | null): Promise<ListResponse<UserListItem>> {
        const queryParams = `orderBy=${orderBy}&orderDirection=${orderDirection}`
            + (search ? `&search=${search}` : "") + (pageToken ? `&pageToken=${pageToken}` : "");
        const result = await axios.get<ListResponse<UserListItem>>(
            `${this.selfHost}/users?${queryParams}`, this.config);

        const items = result.data.items;
        return {
            nextPageToken: result.data.nextPageToken,
            items: items.map(it => {
                if (it.socialType.startsWith("yt")) {
                    it.socialType = "YouTube";
                } else if (it.socialType.startsWith("tw")) {
                    it.socialType = "Twitch";
                } else if (it.socialType.startsWith("vk")) {
                    it.socialType = "VK";
                } else if (it.socialType.startsWith("fb")) {
                    it.socialType = "Facebook";
                } else if (it.socialType.startsWith("tr")) {
                    it.socialType = "Trovo";
                } else {
                    it.socialType = "";
                }
                return {
                    ...it,
                    registeredAt: new Date(it.registeredAt)
                }
            })
        };
    }

    async fetchUserBilling(userId: string): Promise<UserBillingState> {
        const url = `${this.apiHost}/billing/v2/users/${userId}`;
        const result = await axios.get<UserBillingState>(url, this.config);
        return result.data;
    }

    async fetchUserProfile(id: string): Promise<UserProfile> {
        const result = await axios.get<UserProfile>(`${this.selfHost}/users/${id}`, this.config);
        result.data.registeredAt = new Date(result.data.registeredAt);
        result.data.blockedAt = result.data.blockedAt ? new Date(result.data.blockedAt) : undefined;
        return result.data;
    }

    async moderateUser(id: String, block: Boolean): Promise<void> {
        await axios.post<void>(`${this.apiHost}/users/${id}/moderate`, {
            block
        }, this.config);
    }

    async fetchTransactions(streamerId: string): Promise<ListResponse<Transaction>> {
        const result = await axios.get<ListResponse<Transaction>>(
            `${this.selfHost}/users/events?ownerRef=${streamerId}`, this.config);

        return {
            nextPageToken: result.data.nextPageToken,
            items: result.data.items.map(it => {
                it.date = new Date(it.date);
                it.amount = Number.parseFloat(it.amount.toString());
                it.commission = Number.parseFloat(it.commission.toString());
                if (it.payout) {
                    it.amount *= -1;
                }
                it.number = it.number || "";
                it.signature = it.signature || "";
                it.message = it.message || "";
                return it;
            })
        }
    }

    async restartWalletIdentification(userId: string): Promise<void> {
        const url = `${this.apiHost}/users/${userId}/restartWalletIdentification`;
        await axios.post<void>(url, {}, this.config);
    }

    async setApplePayEnabled(userId: string, isEnabled: boolean): Promise<void> {
        const url = `${this.apiHost}/users/${userId}/applepay`;
        await axios.post<void>(url, {isEnabled}, this.config);
    }

    async setBillingSetting(key: BillingSettingKey, value: string): Promise<void> {
        const uri = `${this.apiHost}/billing/v2/settings/${key}`;
        await axios.put<void>(uri, {value}, this.config);
    }

    async setGpayEnabled(userId: string, isEnabled: boolean): Promise<void> {
        const url = `${this.apiHost}/users/${userId}/googlepay`;
        await axios.post<void>(url, {isEnabled}, this.config);
    }

    async setOrderState(id: string, state: string): Promise<void> {
        const url = `${this.apiHost}/billing/v2/orders/${id}`;
        await axios.put<SetOrderStateRequest>(url, {state}, this.config);
    }

    async setUserBillingState(userId: string, newState: RecursivePartial<UserBillingState>): Promise<UserBillingState> {
        const url = `${this.apiHost}/billing/v2/users/${userId}`;
        const result = await axios.post<UserBillingState>(url, newState, this.config);
        return result.data;
    }
}

export default new ApiClient();
